<template>
    <div class="invite-review">
        <vx-card class="invite-review-card">
            <div class="invite-review-card--container">
                <div class="invite-review-card--container--logo">
                    <img width="150" height="60" :src="logo" alt="logo">
                </div>
                <div class="invite-review-card--container--title mt-4">
                    <div v-if="!is_error" class="text-center">
                        <h3 class="mb-3">You've been invited to join</h3>
                        <h4> {{ organisation.name }}</h4>
                    </div>
                    <h5 class="block text-danger mt-2" v-if="is_error && api_errors.token">
                        {{ api_errors.token.join(', ') }}
                    </h5>
                </div>
                <div class="invite-review-card--container--btn mt-base" v-if="!is_error">
                    <vs-button @click="reject" color="danger" size="small" class="mr-3">Reject</vs-button>
                    <vs-button @click="accept" size="small">Accept</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { Request } from '../../utils/requests';
import logo from "../../assets/images/logo/logo-dark.png";

export default {
    data () {
        return {            
            api_errors: {},
            loading: false,
            organisation: {},
            is_error: false
        }
    },
    computed: {
        logo: () => logo
    },
    mounted() {
      console.log(logo)
      this.load_data()  
    },
    methods: {
        load_data() {
            this.loading = true;
            this.$vs.loading();
            this.is_error = false

            let url = this.sprintf(this.$store.state.apiendpoints.get_user_invite, this.$route.params.token)

            Request(this, 'GET', url).then( 
                (res) => {
                    this.organisation = res.organisation
                    this.loading = false;
                    this.$vs.loading.close();
                }, 
                (err) => {
                    this.is_error = true
                    this.loading = false
                    this.$vs.loading.close()
                    if (err.error) {
                        this.api_errors = err.error
                    } else {
                        this.$vs.notify({
                            time: 3000,
                            title: 'Error',
                            text: "An unknown error occurred! Please try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        },
        accept() {
            this._do_action('accept')
        },
        reject() {
            this._do_action('reject')
        },
        _do_action (action) {
            this.loading = true;
            this.$vs.loading();

            let url = this.sprintf(this.$store.state.apiendpoints.get_user_invite, this.$route.params.token)
            let data = {
                action: action
            }

            Request(this, 'POST', url, null, data).then( 
                (res) => {
                    this.loading = false
                    this.$vs.loading.close()

                    this.$vs.notify({
                        time: 3000,
                        title: 'Success',
                        text: "Request Processed Successfully!",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })

                    if (action == 'accept') {
                        this.$router.push({name: 'organisation-chooser'})
                    } else {
                        this.$router.push("/")
                    }
                }, 
                (err) => {
                    this.loading = false
                    this.$vs.loading.close()
                    if (err.error) {
                        this.api_errors = err.error
                        this.$vs.notify({
                            time: 3000,
                            title: 'Error',
                            text: (err.error && err.error.__non_field_errors__) ? err.error.__non_field_errors__.join(',') : 'Please fix the errors and try again!',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 3000,
                            title: 'Error',
                            text: "An unknown error occurred! Please try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        }
    }
}
  
</script>

<style lang="scss">
.invite-review {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.invite-review-card {
    width: 350px;
    &--container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>

